import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link, withPrefix } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

export const BlogPostTemplate = ({
  content,
  contentComponent,
  author,
  tags,
  title,
  date,
  featuredImage,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section" style={{ marginTop: "6rem" }}>
      {helmet || ""}
      <div className="container content">
        <h1 className="title is-size-2 has-text-weight-bold is-bold-light has-text-centered">
          {title}
        </h1>
        <div className="section">
          <div className="columns is-centered has-text-centered">
            <div className="column has-text-grey-dark">{author}</div>
            <div className="column has-text-grey-dark">{date}</div>
          </div>
        </div>
        <div className="container has-text-centered">
          <PreviewCompatibleImage
            imageInfo={{
              image: featuredImage,
              alt: title,
              style: {
                display: "block",
                height: "auto",
                width: "auto",
                maxWidth: "50rem",
                maxHeight: "30rem",
                marginLeft: "auto",
                marginRight: "auto",
              },
            }}
          />
        </div>

        <div className="section">
          <div className="columns">
            <div className="column is-8 is-offset-2 py-3">
              <PostContent content={content} />

              {tags && tags.length ? (
                <div style={{ marginTop: `4rem` }}>
                  <h4>Tags</h4>
                  <ul className="taglist">
                    {tags.map((tag) => (
                      <li key={tag + `tag`}>
                        <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  author: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  featuredImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        featuredImage={post.frontmatter.featuredimage}
        author={post.frontmatter.author}
        date={post.frontmatter.date}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.title} by ${post.frontmatter.author}`}
            />
            <meta
              property="og:image"
              content={post.frontmatter.featuredimage.childImageSharp.fluid.src}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
        author
        tags
      }
    }
  }
`;
